import React, { useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StoreContext } from "../../../core/context/StoreContext";
import {
  deleteCookie,
  showToast,
} from "../../../core/common-service/CommonService";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../../common/useLangNavigate";

const RequestErrorMessage = () => {
  const { setIsLoggedIn } = useContext(StoreContext);
  const { t } = useTranslation();
  let navigate = useLangNavigate();

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        try {
          var response = error.response.data.error;
          const status_code = error.response.status;
          if (status_code === 401) {
            deleteCookie("token");
            sessionStorage.removeItem("token");
            setIsLoggedIn(false);
            navigate("/");
          }
          // hide when payment is not successful
          else if (
            error?.request?.responseURL &&
            error?.request?.responseURL.includes("getPaymentForm") &&
            error?.response?.status === 402
          ) {
          } else {
            // showContactUsPopup();
            showToast({ type: "error", message: response });
          }
        } catch (error) {
          // showContactUsPopup();
          showToast({
            type: "error",
            message: t("error.error_in_your_request"),
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);

  function showContactUsPopup() {
    showToast({ type: "error", message: t("error.contact_us") });
  }

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={4000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnHover
      draggable={false}
      progress={undefined}
      theme="light"
    />
  );
};

export default RequestErrorMessage;
