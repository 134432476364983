import React, { useRef, useState, useEffect } from "react";
import "./Payment.css";
import KRGlue from "@lyracom/embedded-form-glue";
import {
  postCreatePaymentForm,
  getOnPaymentCallback,
  PAYMENT_GATEWAY_PUBLIC_KEY,
} from "../../core/api/Api";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";
import useLangNavigate from "../common/useLangNavigate";

const Payment = () => {
  const mounted = useRef(true);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const navigate = useLangNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  // let { service_session_id, plan_id } = useParams();
  const { service_session_id, plan_id, is_add_chapter } = location.state || {};

  const [isPaymentFormRendered, setIsPaymentFormRendered] = useState(false);

  const loadPaymentForm = async (formToken) => {
    const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
    const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

    const { KR } = await KRGlue.loadLibrary(
      endpoint,
      publicKey
    ); /* Load the remote library */

    await KR.setFormConfig({
      /* set the minimal configuration */
      formToken,
      "kr-language": i18n.language /* to update initialization parameter */,
    });

    await KR.renderElements(
      "#myPaymentForm"
    ); /* Render the payment form into myPaymentForm div */

    setIsPaymentFormRendered(true);

    await KR.onSubmit(async (res) => {
      const orderStatus = res.clientAnswer.orderStatus;
      if (orderStatus === "PAID") {
        getOnPaymentCallback(service_session_id)
          .then((res) => {
            const response = res?.data?.success;
            if (mounted.current && response) {
              showToast({ type: "success", message: response });

              navigate(
                `/thankyou/purchase?order=${service_session_id}&plan=${plan_id}`
              );
            }
          })
          .catch((e) => {});
      }
      return false; // Return false to prevent the redirection
    });

    await KR.onError(async (res) => {
      const detailedErrorMessage = res.detailedErrorMessage;
      getOnPaymentCallback(service_session_id);
      showToast({ type: "error", message: detailedErrorMessage });
    });
  };

  // useEffect(() => {

  //   const _totalNbUsers = async () => {
  //     const {data} = await getTotalUsersCount()
  //     const {success: {total_users_count}} = data

  //     setNbOfUsers(total_users_count)
  //   }

  //   _totalNbUsers()
  // }, [nbOfUsers])

  useEffect(() => {
    window.scroll(0, 0);
    async function setupPaymentForm() {
      try {
        postCreatePaymentForm({
          service_session_id,
          plan_id,
          is_add_chapter,
        })
          .then(async (res) => {
            const response = res?.data?.success;
            if (mounted.current && response) {
              const formToken = response?.formToken;
              loadPaymentForm(formToken);
            }
          })
          .catch((e) => {});
      } catch (error) {
        navigate(-1);
        setMessage(error + " (see console for more details)");
      }
    }
    setupPaymentForm();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section
      className="aai-features py-100 payment"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      {/* {nbOfUsers && <h2 className="text-center font-semibold" style={{marginBottom: '1rem', fontSize: '1.3rem'}}>
        {nbOfUsers} {t('people_have_already_written_their_stories_with_taLegacy')}
      </h2>} */}
      <div className="form">
        <div className="d-flex align-items-center justify-content-center">
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
          <div data-test="payment-message">{message}</div>
        </div>
        {isPaymentFormRendered && (
          <div className="centered-div">{t("for_more_info_contact_us")}</div>
        )}
      </div>
    </section>
  );
};

export default Payment;
