import React, { useContext, useState } from "react";
import "./Register.css";
import { Link, useLocation } from "react-router-dom";
import {
  postLogin,
  postRegister,
  setDefaultHeader,
  postVerifyRegistrationWithoutOtp,
} from "../../core/api/Api";
import { useTranslation } from "react-i18next";
import {
  setCookie,
  showToast,
  buildLocalizedPath,
} from "../../core/common-service/CommonService";
import { MARKETING_DOMAIN_URL } from "../../core/constants/constants";
import { LoginSocialGoogle } from "reactjs-social-login";
import { StoreContext } from "../../core/context/StoreContext";
import useLangNavigate from "../common/useLangNavigate";
import Captcha from "../_components/Captcha";

function Register() {
  const { setIsLoggedIn, setUser } = useContext(StoreContext);

  const { t } = useTranslation();
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const code = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const navigate = useLangNavigate();

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };

  const onCityChange = (e) => {
    setCity(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onShowHidePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const onTermsAgreeChange = (e) => {
    setIsTermsAgreed((prev) => !prev);
  };

  const handleSubmit = (e) => {
    if (isLoading) return;
    setIsLoading(true);

    if (password !== confirm_password) {
      showToast({ type: "error", message: t("error.password_does_not_match") });
      setIsLoading(false);
      return;
    }

    if (email !== confirm_email) {
      showToast({
        type: "error",
        message: t("error.email_does_not_match"),
      });
      setIsLoading(false);
      return;
    }

    if (!captchaToken) {
      showToast({
        type: "error",
        message: t("Please complete the CAPTCHA"),
      });
      setIsLoading(false);
      return;
    }

    if (!isTermsAgreed) {
      showToast({
        type: "error",
        message: t("error.please_accept_terms_conditions"),
      });
      setIsLoading(false);
    } else {
      postRegister({
        first_name: firstName,
        last_name: lastName,
        email,
        city,
        password,
        confirm_password,
      })
        .then((res) => {
          const status_code = res?.status;
          if (status_code === 200) {
            const trial_session = localStorage.getItem("trial_session");
            postVerifyRegistrationWithoutOtp({ email, trial_session })
              .then((res) => {
                const status_code = res?.status;
                const response = res?.data?.success;
                if (status_code === 200) {
                  const token = response?.token;
                  const user = response?.user;
                  if (user?.is_verified) {
                    const service_session_id =
                      response?.service_session_id ?? null;
                    setDefaultHeader("Bearer " + token);
                    setIsLoggedIn(true);
                    setUser(user);
                    setTimeout(() => {
                      setCookie("token", token);
                      navigate("/thankyou/register");
                      if (service_session_id) {
                        localStorage.removeItem("trial_session");
                      }
                    }, 300);
                  }
                }
              })
              .catch((e) => {});
          } else if (status_code === 202) {
            setIsLoading(false);
            navigate("/login");
          } else {
            setIsLoading(false);
            showToast({
              type: "error",
              message: t("error.error_while_registering"),
            });
          }
        })
        .catch((e) => {
          const response = e?.response;
          const status_code = response?.status;
          if (status_code === 409) {
            setIsLoading(false);
          }
          setIsLoading(false);
        });
    }
  };

  const _postLogin = (body) => {
    postLogin(body)
      .then((res) => {
        setIsLoading(false);
        const status_code = res?.status;
        const response = res?.data?.success;
        if (status_code === 200) {
          const token = response?.token;
          const user = response?.user;
          if (user?.is_verified) {
            const service_session_id = response?.service_session_id ?? null;
            setDefaultHeader("Bearer " + token);
            setIsLoggedIn(true);
            setUser(user);
            setTimeout(() => {
              setCookie("token", token);
              if (service_session_id) {
                navigate("/questions/" + service_session_id);
                localStorage.removeItem("trial_session");
              } else {
                const from = location.state?.from || "/"; // default to home page if no previous location is found
                navigate(from, "", { state: location.state });
              }
            }, 300);
          } else {
            showToast({ type: "error", message: t("error.user_not_allowed") });
          }
        }
      })
      .catch((e) => {
        const response = e?.response;
        const status_code = response?.status;
        if (status_code === 400) {
          navigate("/thankyou/register", "", { state: { email } });
        }
        setIsLoading(false);
      });
  };

  const onResolveGoogleLogin = ({ provider, data }) => {
    _postLogin({ access_token: data.access_token });
  };

  return (
    <section
      className="aai-signup-in"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
            <div className="position-relative">
              {/* <img src={img} className="img-fluid aai-signup-in-img" alt="" /> */}
            </div>
          </div>
          <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
            <div className="aai-form-wrapper">
              <div className="">
                <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
                  <h2 className="aai-form-title">
                    {t("signup_for_an_account")}
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder={t("enter_your_first_name")}
                          value={firstName}
                          onChange={onFirstNameChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="last_name"
                          name="last_name"
                          type="text"
                          placeholder={t("enter_your_last_name")}
                          value={lastName}
                          onChange={onLastNameChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="email"
                          name="email"
                          type="email"
                          placeholder={t("enter_your_email")}
                          value={email}
                          onChange={onEmailChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="confirm_email"
                          name="confirm_email"
                          type="email"
                          placeholder={t("enter_your_email")}
                          value={confirm_email}
                          onChange={onConfirmEmailChange}
                          onPaste={(e) => e.preventDefault()} // Disable paste
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          className="form-control shadow-none"
                          id="city"
                          name="city"
                          type="text"
                          placeholder={t("enter_your_city")}
                          value={city}
                          onChange={onCityChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="aai-form-container position-relative">
                        <input
                          className="form-control shadow-none"
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("enter_your_password")}
                          value={password}
                          onChange={onPasswordChange}
                        />
                        <button
                          className="aai-form-eye"
                          onClick={onShowHidePassword}
                        >
                          <i
                            className={
                              "fa-regular fa-eye" +
                              (showPassword ? "" : "-slash")
                            }
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div className="aai-form-container position-relative">
                        <input
                          className="form-control shadow-none"
                          id="confirm_password"
                          name="confirm_password"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("enter_your_password")}
                          value={confirm_password}
                          onChange={onConfirmPasswordChange}
                        />
                        <button
                          className="aai-form-eye"
                          onClick={onShowHidePassword}
                        >
                          <i
                            className={
                              "fa-regular fa-eye" +
                              (showPassword ? "" : "-slash")
                            }
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <div
                        className="aai-form-container d-flex gap-3 position-relative"
                        onClick={onTermsAgreeChange}
                      >
                        <input
                          type="checkbox"
                          className="shadow-none"
                          checked={isTermsAgreed}
                        />
                        <label className="aai-form-check-label">
                          {t("accept_privacy_terms_checkbox")}
                          &nbsp;
                          <a
                            href={
                              MARKETING_DOMAIN_URL +
                              "/conditions-generales-dutilisation"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="aai-copyright-text"
                          >
                            {t("terms_and_conditions")}
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <Captcha onVerify={(token) => setCaptchaToken(token)} />
                    </div>
                    <div className="col-lg-12">
                      <div className="d-grid">
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          disabled={isLoading}
                          className={`aai-btn btn-pill-solid ${
                            isLoading ? "disabled" : ""
                          }`}
                        >
                          {isLoading ? "loading..." : t("signup")}
                        </button>
                      </div>
                    </div>
                    <LoginSocialGoogle
                      // isOnlyGetToken
                      scope={"https://www.googleapis.com/auth/userinfo.email"}
                      client_id={process.env.REACT_APP_GG_APP_ID || ""}
                      onResolve={onResolveGoogleLogin}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <div className="row aai-auth-links">
                        <div className="col-lg-12 mb-3 mb-lg-0">
                          <Link
                            href="#"
                            className="aai-auth-link w-100 justify-content-center d-flex align-items-center"
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.1258 11.2139C21.1258 10.4225 21.0603 9.84497 20.9185 9.24609H11.6973V12.818H17.1099C17.0008 13.7057 16.4115 15.0425 15.102 15.9408L15.0836 16.0603L17.9992 18.2738L18.2012 18.2936C20.0563 16.6145 21.1258 14.1441 21.1258 11.2139"
                                fill="#4285F4"
                              />
                              <path
                                d="M11.6976 20.6248C14.3494 20.6248 16.5755 19.7692 18.2016 18.2934L15.1024 15.9405C14.273 16.5073 13.1599 16.903 11.6976 16.903C9.10043 16.903 6.89609 15.224 6.11031 12.9033L5.99513 12.9129L2.96347 15.2122L2.92383 15.3202C4.53888 18.4644 7.85634 20.6248 11.6976 20.6248Z"
                                fill="#34A853"
                              />
                              <path
                                d="M6.10908 12.9034C5.90174 12.3045 5.78175 11.6628 5.78175 10.9997C5.78175 10.3366 5.90174 9.695 6.09817 9.09612L6.09268 8.96857L3.02303 6.63232L2.92259 6.67914C2.25695 7.98388 1.875 9.44905 1.875 10.9997C1.875 12.5504 2.25695 14.0155 2.92259 15.3203L6.10908 12.9034"
                                fill="#FBBC05"
                              />
                              <path
                                d="M11.6977 5.09664C13.5419 5.09664 14.7859 5.87733 15.4953 6.52974L18.2671 3.8775C16.5648 2.32681 14.3494 1.375 11.6977 1.375C7.85637 1.375 4.53889 3.53526 2.92383 6.6794L6.09942 9.09638C6.89612 6.77569 9.10047 5.09664 11.6977 5.09664"
                                fill="#EB4335"
                              />
                            </svg>
                            <span>
                              {t("signup")} {t("with")} Google
                            </span>
                          </Link>
                        </div>
                      </div>
                    </LoginSocialGoogle>
                  </div>
                  <div className="text-center mt-3">
                    <p className="aai-form-support-text">
                      {t("already_have_an_account")}?{" "}
                      <Link
                        to={buildLocalizedPath("/login")}
                        className="aai-form-support-link text-decoration-underline"
                      >
                        {t("signin")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
