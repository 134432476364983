import React, { useContext } from "react";
import "./Footer.css";
import { MARKETING_DOMAIN_URL } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../context/StoreContext";

const Footer = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useContext(StoreContext);

  return (
    <footer
      className="aai-footer pt-120"
      style={
        {
          // background: `url(${bg}) no-repeat center center/cover`,
        }
      }
    >
      <div className="container">
        <div className="aai-footer-navigation-widgets py-80">
          <div className="row">
            <div className="col-12 mt-5 mt-lg-0">
              <div className="row">
                <div
                  className="col-xl-4 col-lg-4 col-md-4 mb-5 mb-lg-0"
                  // data-aos="fade-up"
                  // data-aos-delay="100"
                  // data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    {/* <h3 className="aai-footer-nav-title">Artworks</h3> */}
                    <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <a
                          href={MARKETING_DOMAIN_URL + "/notre-solution/"}
                          className="aai-footer-nav-link"
                        >
                          {t("our_solution")}
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a
                          href={MARKETING_DOMAIN_URL + "/faq/"}
                          className="aai-footer-nav-link"
                        >
                          {t("faq")}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 mb-5 mb-lg-0"
                  // data-aos="fade-up"
                  // data-aos-delay="300"
                  // data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    {/* <h3 className="aai-footer-nav-title">Artworks</h3> */}
                    <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <a
                          href={MARKETING_DOMAIN_URL + "/blog/"}
                          className="aai-footer-nav-link"
                        >
                          {t("blog")}
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a
                          href={MARKETING_DOMAIN_URL + "/contact/"}
                          className="aai-footer-nav-link"
                        >
                          {t("contact")}
                        </a>
                      </li>
                      {isLoggedIn && (
                        <li className="aai-footer-nav-list-item">
                          <a
                            href="tel:+33675670621"
                            className="aai-footer-nav-link"
                          >
                            {t("call_us")}: 0675670621
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4"
                  // data-aos="fade-up"
                  // data-aos-delay="500"
                  // data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    <h3 className="aai-footer-nav-title">
                      {t("footer.follow_us")}
                    </h3>
                    <div className="aai-social-links d-flex">
                      <a
                        href="https://www.facebook.com/people/TaLegacy/61556302516883/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: "#3b5998" }}
                      >
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/talegacy.remembered/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: "#F2295B" }}
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/talegacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: "#0077b5" }}
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                    {/* <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <a href="/services" className="aai-footer-nav-link">
                          Global Partners
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a href="/about" className="aai-footer-nav-link">
                          Forum
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a href="/services" className="aai-footer-nav-link">
                          Virtual World
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a href="/about" className="aai-footer-nav-link">
                          Community
                        </a>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <a href="/services" className="aai-footer-nav-link">
                          Brand Assets
                        </a>
                      </li>
                    </ul> */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aai-footer-copyright">
          <p>{t("footer.description")}</p>
          <div className="row mt-4">
            <div className="col-4">
              <a
                href={
                  MARKETING_DOMAIN_URL + "/conditions-generales-dutilisation/"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="aai-copyright-text me-4"
              >
                {t("footer.terms_of_service")}
              </a>
              <br />
              <a
                href={
                  MARKETING_DOMAIN_URL + "/politique-de-protection-des-donnees/"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="aai-copyright-text"
              >
                {t("footer.data_protection_policy")}
              </a>
            </div>
            <div className="col-4">
              <a
                href={MARKETING_DOMAIN_URL + "/mentions-legales/"}
                target="_blank"
                rel="noopener noreferrer"
                className="aai-copyright-text"
              >
                {t("footer.legal_notice")}
              </a>
            </div>
            <div className="col-4">
              <a
                href="/#"
                rel="noopener noreferrer"
                className="aai-copyright-text"
              >
                {t("footer.copyright.text")} © {new Date().getFullYear()}.{" "}
                {t("footer.copyright.description")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
