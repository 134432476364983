import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import arrowDesktop from "../../../../src/assets/svg/arrow-Desktop.svg";
import arrowMobile from "../../../../src/assets/svg/arrow-Mobile.svg";
import asterisk from "../../../../src/assets/svg/asterisk.svg";
import cardMockup from "../../../../src/assets/img/hero/Credit-Card-Mockup.png";
import { useTranslation } from "react-i18next";
import "./GiftCardPayment.css";
import {
  PAYMENT_GATEWAY_PUBLIC_KEY,
  postCreateGiftCardPaymentForm,
  getOnGiftCardPaymentCallback,
} from "../../../core/api/Api";
import KRGlue from "@lyracom/embedded-form-glue";
import { showToast } from "../../../core/common-service/CommonService";
import { saveAs } from "file-saver";
import useIsMobile from "../../../core/hooks/useMobile";
import useLangNavigate from "../../common/useLangNavigate";

const GiftCardPayment = () => {
  const mounted = useRef(true);

  const { t } = useTranslation();

  const [message, setMessage] = useState("INITIAL");
  const [email, setEmail] = useState("");
  const { i18n } = useTranslation();
  const navigate = useLangNavigate();
  const location = useLocation();
  const { plan_id } = location.state || {};
  const [isPaymentFormRendered, setIsPaymentFormRendered] = useState(false);

  const onEmailChange = (e) => {
    const val = e.target.value;
    setEmail(val);
  };

  const setupPaymentForm = async () => {
    const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
    const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

    try {
      postCreateGiftCardPaymentForm({ plan_id, email })
        .then(async (res) => {
          const response = res?.data?.success;
          if (mounted.current && response) {
            const formToken = response?.formToken;
            const orderId = response?.orderId;
            setMessage("PENDING");
            window.scrollTo({ top: 0, behavior: "smooth" });

            const { KR } = await KRGlue.loadLibrary(
              endpoint,
              publicKey
            ); /* Load the remote library */

            await KR.setFormConfig({
              /* set the minimal configuration */
              formToken,
              "kr-language":
                i18n.language /* to update initialization parameter */,
            });

            await KR.renderElements(
              "#myPaymentForm"
            ); /* Render the payment form into myPaymentForm div */

            setIsPaymentFormRendered(true);

            await KR.onSubmit(async (res) => {
              const orderStatus = res.clientAnswer.orderStatus;
              if (orderStatus === "PAID") {
                getOnGiftCardPaymentCallback(plan_id, orderId, email)
                  .then((res) => {
                    const response = res?.data?.success;
                    if (mounted.current && response) {
                      showToast({
                        type: "success",
                        message: response?.message,
                      });
                      saveAs(response?.url, response?.name);
                      navigate(
                        `/thankyou/purchase?order=${orderId}&plan=${plan_id}`
                      );
                    }
                  })
                  .catch((e) => {});
              }
              return false; // Return false to prevent the redirection
            });
          }
        })
        .catch((e) => {});
    } catch (error) {
      navigate(-1);
      setMessage(error + " (see console for more details)");
    }
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const isMobile = useIsMobile();

  return (
    <section className="gift-card-section">
      {/* Steps Section */}
      <div
        className="steps-container"
        style={{
          display: isMobile ? "block" : "flex",
        }}
      >
        {/* Step 1 */}
        {message === "INITIAL" && (
          <div className="step">
            <div className="step-header">
              <h1 className="step-number">1</h1>
              <p className="step-text">
                {t("gift_card_step1")}{" "}
                <span className="bold-text">{t("gift_card_step1_bold")}</span>
              </p>
            </div>
            {!isMobile && (
              <img src={arrowDesktop} alt="Arrow" className="arrow-icon" />
            )}
          </div>
        )}

        {/* Step 2 */}
        {message === "INITIAL" && (
          <div className="step">
            <div className="step-header">
              <h1 className="step-number">2</h1>
              <p className="step-text">{t("gift_card_step2")}</p>
            </div>
          </div>
        )}

        {/* Step 3 */}
        {message === "INITIAL" && (
          <div className="step">
            <div className="step-header">
              <h1 className="step-number">3</h1>
              <p className="step-text">{t("gift_card_step3")}</p>
            </div>
          </div>
        )}
        {isMobile && message === "INITIAL" && (
          <img src={arrowMobile} alt="Arrow" className="arrow-icon" />
        )}
      </div>

      <div className="container">
        {message === "INITIAL" && (
          <div>
            <div className="col-lg-8">
              <div
                className="d-flex align-items-center"
                style={{
                  flexDirection: isMobile ? "column" : "row", // Stack on mobile, row on desktop
                  alignItems: "center", // Vertically align items
                  width: "100%",
                }}
              >
                {/* Email Input */}
                <div
                  className="aai-form-container"
                  style={{
                    width: isMobile ? "100%" : "70%", // Full width on mobile, 70% width on desktop
                    marginBottom: isMobile ? "1rem" : "0", // Space below on mobile
                  }}
                >
                  <input
                    className="email-input-container"
                    id="email"
                    name="email"
                    type="email"
                    placeholder={t("enter_your_email")}
                    value={email}
                    onChange={onEmailChange}
                  />
                </div>

                {/* Continue Button */}
                <button
                  onClick={setupPaymentForm}
                  type="submit"
                  className="validate-btn"
                  style={{
                    width: isMobile ? "100%" : "auto", // Full width on mobile, auto on desktop
                    marginLeft: isMobile ? "0" : "1rem", // Space between input and button
                  }}
                >
                  {t("continue")}
                </button>
                {/* Card Image */}
                <div
                  className="d-flex justify-content-center"
                  style={{
                    marginLeft: isMobile ? "0" : "1rem", // Space between button and image
                    marginTop: isMobile ? "1rem" : "0", // On mobile, separate the image with some margin
                    display: "flex", // Ensure the image stays aligned next to button on larger screens
                    alignItems: "center", // Center the image vertically with button
                  }}
                >
                  <img
                    src={cardMockup} // Your image source
                    alt="Card"
                    style={{
                      width: isMobile ? "250px" : "600px", // Adjust size for mobile and desktop
                      height: "300px",
                    }}
                  />
                </div>
              </div>
            </div>
            <p className="email-info-text">{t("email_info_text")}</p>
          </div>
        )}

        {message === "PENDING" && (
          <div>
            <div className="form">
              <div className="d-flex align-items-center justify-content-center">
                <div id="myPaymentForm">
                  <div
                    className="kr-smart-form"
                    kr-card-form-expanded="true"
                  ></div>
                </div>
                {message === "PAID" && (
                  <div data-test="payment-message">{message}</div>
                )}
              </div>
              {isPaymentFormRendered && (
                <div className="centered-div">
                  {t("for_more_info_contact_us")}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default GiftCardPayment;
