import React, { useRef, useState, useEffect } from "react";
import { getPlansForGift } from "../../core/api/Api";
import PlanCard from "../common/plan-card/PlanCard";
import useLangNavigate from "../common/useLangNavigate";

const GiftCard = () => {
  const mounted = useRef(true);
  const [plans, setPlans] = useState([]);
  const navigate = useLangNavigate();

  const getGetPlansForGift = () => {
    getPlansForGift()
      .then((res) => {
        const response = res?.data?.success;
        if (mounted.current && response) {
          setPlans(response);
        }
      })
      .catch((e) => {});
  };

  const onChoosePlanClick = (item) => (e) => {
    const obj = {
      plan_id: item?.id,
    };
    navigate("/gift-card-payment", "", { state: { ...obj } });
  };

  useEffect(() => {
    getGetPlansForGift();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section className="aai-features py-100 payment">
      <div className="container">
        {plans.map((item, index) => (
          <PlanCard
            key={index}
            item={item}
            onButtonClick={onChoosePlanClick(item)}
          />
        ))}
      </div>
    </section>
  );
};

export default GiftCard;
