import { useNavigate, useLocation } from "react-router-dom";

const useLangNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const supportedLanguages = ["en", "fr", "es", "it"];

  const langNavigate = (to, language, state) => {
    // Extract the language code from the URL path
    const pathSegments = location.pathname.split("/");
    const extractedLang = pathSegments[1]; // language should be in the first segment after "/"
    const currentLang = supportedLanguages.includes(extractedLang)
      ? extractedLang
      : "en";

    // Get the path without the language prefix, to avoid accumulation
    const pathWithoutLang = location.pathname.replace(`/${currentLang}`, "");

    let newPath;
    const goTo = to.startsWith("/") ? to : `/${to}`;
    if (language && supportedLanguages.includes(language)) {
      // If a new language is provided, replace the current language in the URL
      newPath = `/${language}${pathWithoutLang}`;
      if (to) newPath = `/${language}${goTo}`;
    } else {
      // If no language is provided, keep the current language in the new path
      newPath = `/${currentLang}${goTo}`;
    }

    // Pass state along with the navigation
    navigate(newPath, state);
  };

  return langNavigate;
};

export default useLangNavigate;
