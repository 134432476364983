import i18n from "i18next";
import LangageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import {
  getCookie,
  getBrowserLanguage,
} from "./core/common-service/CommonService";

i18n
  .use(LangageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: window.location.host.startsWith("localhost"),
    lng: getCookie("lng") ?? "en",
    fallbackLng: getBrowserLanguage() ?? "en",
    returnObjects: true,
  });
