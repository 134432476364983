import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { postResetPassword } from "../../core/api/Api";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";
import useLangNavigate from "../common/useLangNavigate";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useLangNavigate();

  const searchParams = new URLSearchParams(location.search);
  const param_token = searchParams.get("token") ?? "";

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onShowHidePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    postResetPassword({ token: param_token, password, confirm_password })
      .then((res) => {
        setLoading(false);
        const status_code = res?.status;
        if (status_code === 200) {
          navigate("/login");
        } else {
          showToast({ type: "error", message: t("error.wrong_email") });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <section
      className="aai-signup-in"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
            <div className="position-relative">
              {/* <img src={img} className="img-fluid aai-signup-in-img" alt="" /> */}
            </div>
          </div>
          <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
            <div className="aai-form-wrapper">
              <div className="">
                <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
                  <h2 className="aai-form-title">
                    {t("enter_your_new_password")}
                  </h2>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-12 mb-2">
                  <div className="aai-form-container position-relative">
                    <input
                      className="form-control shadow-none"
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("enter_your_password")}
                      value={password}
                      onChange={onPasswordChange}
                    />
                    <button
                      className="aai-form-eye"
                      onClick={onShowHidePassword}
                    >
                      <i
                        className={
                          "fa-regular fa-eye" + (showPassword ? "" : "-slash")
                        }
                      ></i>
                    </button>
                  </div>
                </div>
                <div className="col-lg-12 mb-2">
                  <div className="aai-form-container position-relative">
                    <input
                      className="form-control shadow-none"
                      id="confirm_password"
                      name="confirm_password"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("enter_your_password_again")}
                      value={confirm_password}
                      onChange={onConfirmPasswordChange}
                    />
                    <button
                      className="aai-form-eye"
                      onClick={onShowHidePassword}
                    >
                      <i
                        className={
                          "fa-regular fa-eye" + (showPassword ? "" : "-slash")
                        }
                      ></i>
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="d-grid">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      disabled={loading}
                      className="aai-btn btn-pill-solid"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
